import React from "react"
import Toggler from "components/Toggler"
import styles from "./styles.scss"
import { AppContext } from "App"
import { useTranslation } from "react-i18next"
import { IProofSetting } from ".."

const ProofSetting: React.FC<{
  settings: IProofSetting
  toggleIsSuggestionMediaOrdersRadio: () => void
  toggleIsVoucherKeyRedio: () => void
}> = ({
  settings,
  toggleIsSuggestionMediaOrdersRadio,
  toggleIsVoucherKeyRedio,
}) => {
  const { t } = useTranslation()


  const isMediaUser = React.useContext(AppContext).currentUserSession.user
    ?.isMediaUser

  return (
    <div className={styles.suggestionMediaOrders}>
      <b className={styles.title}>{t("proof:showHideProofCol")}</b>

        <div>
          <label id="thank-advert">
            <Toggler
              checked={settings.isSuggestionMediaOrders}
              onChange={toggleIsSuggestionMediaOrdersRadio}
            />
            <span>{t("proof:thanksAdSuggest")}</span>
          </label>
        </div>
 

      <div>
        {!isMediaUser && (
          <label   id={"memory-room-key"}>
            <Toggler
              checked={settings.isVoucherKey}
              onChange={toggleIsVoucherKeyRedio}
            
            />
            <span>{t("proof:keyToMemoryRoom")}</span>
          </label>
        )}
      </div>
    </div>
  )
}

export default ProofSetting
