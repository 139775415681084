import React from "react"
import styles from "./styles.scss"
import { useTranslation } from "react-i18next"
import { MaterialProofSettingFeeViews } from "schema"
import { IProofSetting } from ".."
import TooltipInfo from "./TooltipInfo"
export enum ItemIds {
  SEPARATE = "separate",
  FIRST = "first",
  ALL = "all",
  HIDE = "hide",
  EMPTY = "",
}
const ViewingOptions: React.FC<{
  checkViewingOptions: (name: string, value: string) => void
  proofSetting: IProofSetting
}> = (props) => {
  const { t } = useTranslation("proof")
  const {
    SHOW,
    MERGE_ON_ALL,
    MERGE_ON_FIRST,
    HIDE,
  } = MaterialProofSettingFeeViews
  const optionsDevRef = React.useRef<HTMLDivElement>(null)
  const EXTRA_HIGHT_GAP = 135
  const [position, setPosition] = React.useState({
    top: -EXTRA_HIGHT_GAP,
    left: 0,
  })
  const [showTooltip, setShowTooltip] = React.useState(false)
  const [itemId, setItemId] = React.useState<ItemIds>(ItemIds.EMPTY)
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    props.checkViewingOptions(name, value)
  }
  const handleInfoDevPosition = (e: React.MouseEvent<HTMLElement>) => {
    if (!optionsDevRef.current) return
    setShowTooltip(true)
    setItemId(e.currentTarget.id as ItemIds)
    const parent = optionsDevRef.current?.getBoundingClientRect()
    const currentChild = e.currentTarget.getBoundingClientRect()
    setPosition({
      top: currentChild.top - parent.top - EXTRA_HIGHT_GAP,
      left: Math.abs(currentChild.left - parent.left) - 35,
    })
  }
  const restInfoDevPosition = () => {
    setShowTooltip(false)
    setPosition({
      top: -EXTRA_HIGHT_GAP,
      left: 0,
    })
  }
  return (
    <div className={styles.container}>
      <div className={styles.options} ref={optionsDevRef}>
        <div className={styles.background} />
        <TooltipInfo
          showTooltip={showTooltip}
          position={position}
          itemId={itemId}
        />
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableRowHeader}>
              <th>{t("viewsOption")}</th>
              <th
                onMouseEnter={handleInfoDevPosition}
                onMouseLeave={restInfoDevPosition}
                id={ItemIds.SEPARATE}
              >
                {t("separate")}
              </th>
              <th
                onMouseEnter={handleInfoDevPosition}
                onMouseLeave={restInfoDevPosition}
                id={ItemIds.FIRST}
              >
                {t("first")}
              </th>
              <th
                onMouseEnter={handleInfoDevPosition}
                onMouseLeave={restInfoDevPosition}
                id={ItemIds.ALL}
              >
                {t("all")}
              </th>
              <th
                onMouseEnter={handleInfoDevPosition}
                onMouseLeave={restInfoDevPosition}
                id={ItemIds.HIDE}
              >
               {t("hide")}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={styles.tableRow}>
              <th>{t("caseFee")}</th>
              <td>
                <input
                  type="radio"
                  name="viewCaseFee"
                  id="viewCaseFee"
                  value={SHOW}
                  checked={props.proofSetting.viewCaseFee === SHOW}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewCaseFee"
                  id="caseFeeFirst"
                  value={MERGE_ON_FIRST}
                  checked={props.proofSetting.viewCaseFee === MERGE_ON_FIRST}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewCaseFee"
                  id="caseFeeAll"
                  value={MERGE_ON_ALL}
                  checked={props.proofSetting.viewCaseFee === MERGE_ON_ALL}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewCaseFee"
                  id="caseFeeHide"
                  value={HIDE}
                  checked={props.proofSetting.viewCaseFee === HIDE}
                  onChange={handleRadioChange}
                />
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <th>Alis*</th>
              <td>
                <input
                  type="radio"
                  name="viewAlis"
                  id="alisSeparate"
                  value={SHOW}
                  checked={props.proofSetting.viewAlis === SHOW}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewAlis"
                  id="alisFeeFirst"
                  value={MERGE_ON_FIRST}
                  checked={props.proofSetting.viewAlis === MERGE_ON_FIRST}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewAlis"
                  id="alisFeeAll"
                  value={MERGE_ON_ALL}
                  checked={props.proofSetting.viewAlis === MERGE_ON_ALL}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewAlis"
                  id="alisFeeHide"
                  value={HIDE}
                  checked={props.proofSetting.viewAlis === HIDE}
                  onChange={handleRadioChange}
                />
              </td>
            </tr>
            <tr className={styles.tableRow}>
              <th>Familjsidan**</th>
              <td>
                <input
                  type="radio"
                  name="viewFamiljesidan"
                  id="familyPageSeparate"
                  value={SHOW}
                  checked={props.proofSetting.viewFamiljesidan === SHOW}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewFamiljesidan"
                  id="familyPageFirst"
                  value={MERGE_ON_FIRST}
                  checked={
                    props.proofSetting.viewFamiljesidan === MERGE_ON_FIRST
                  }
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewFamiljesidan"
                  id="familyPageFeeAll"
                  value={MERGE_ON_ALL}
                  checked={props.proofSetting.viewFamiljesidan === MERGE_ON_ALL}
                  onChange={handleRadioChange}
                />
              </td>
              <td></td>
            </tr>
            <tr className={styles.tableRow}>
              <th>Egen hemsida</th>
              <td>
                <input
                  type="radio"
                  name="viewAgencyHomepage"
                  id="viewAgencyHomepageSeparate"
                  value={SHOW}
                  checked={props.proofSetting.viewAgencyHomepage === SHOW}
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewAgencyHomepage"
                  id="viewAgencyHomepageFirst"
                  value={MERGE_ON_FIRST}
                  checked={
                    props.proofSetting.viewAgencyHomepage === MERGE_ON_FIRST
                  }
                  onChange={handleRadioChange}
                />
              </td>
              <td>
                <input
                  type="radio"
                  name="viewAgencyHomepage"
                  id="viewAgencyHomepageFeeAll"
                  value={MERGE_ON_ALL}
                  checked={
                    props.proofSetting.viewAgencyHomepage === MERGE_ON_ALL
                  }
                  onChange={handleRadioChange}
                />
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default ViewingOptions

