import gql from "graphql-tag"
import PREVIEW_IMAGE_FRAGMENT from "./PreviewImageFragment"
import AREA_FRAGMENT from "./AreaFragment"
import USER_INTERFACE_FRAGMENT from "./UserInterfaceFragment"

const ORDER_FRAGMENT = gql`
  fragment OrderFragment on Order {
    id
    customerFriendlyId
    document {
      id
    }
    client {
      mainSystem
      isTaps5
    }
    alerting {
      smallText
      text
      type
    }
    mediaOrder {
      timeBookingStop
      isLocked
      publishTimes {
        time
      }
      documentFormat {
        id
        documentFormatCollection {
          id
          name
        }
        mediaAdaptedName: friendlyName(isShownWithMedia: true)
        documentType {
          id
          name
          proofSetting {
            isSuggestionMediaOrders
            isVoucherKey
            viewAlis
            viewCaseFee
            viewFamiljesidan
            viewAgencyHomepage
          }
        }
      }
      publishConversation {
        validateErrorTexts
        messageFromMedia
        codeLate
        isCodeLate
        isMessageToMedia
        isMediaSystemOrderNumber
        isMediaCustomerSubriberNumber
        publishReadyStatus
      }
      publishChannels {
        inFamiljesidan
        inMinnesrummet
        inAgency
      }
      edit {
        userInterface {
          ...userInterfaceFragment
        }
        resources {
          styleCssSpecialCharacters
        }
        editModelAdvert {
          isEnabledUpdateOrdersContent
          basePage {
            areas {
              ...AreaFragment
            }
          }
        }
        history {
          countStepBack
        }
      }
      preview {
        ...PreviewImageFragment
        price(
          withVAT: true
          withRounding: STANDARD
          priceView: WITH_SERVICE_ORDER
        ) {
          currency
          customer {
            priceContract
            priceContractOverride
          }
          endCustomer {
            pricePayRounded
          }
        }
      }
      media {
        id
        friendlyName
      }
      status {
        color
        status
      }
    }
  }
  ${USER_INTERFACE_FRAGMENT}
  ${PREVIEW_IMAGE_FRAGMENT}
  ${AREA_FRAGMENT}
`

export default ORDER_FRAGMENT
