import React from "react"
import styles from "./styles.scss"
import cx from "classnames"
import { ItemIds } from ".."
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFile } from "@fortawesome/free-regular-svg-icons"
import { useTranslation } from "react-i18next"
const TooltipInfo: React.FC<{
  showTooltip: boolean
  position: { top: number; left: number }
  itemId: ItemIds
}> = ({ showTooltip, position, itemId }) => {
  const { t } = useTranslation("proof")
  return (
    <div
      className={cx(styles.tooltipInfo, {
        [styles.show]: showTooltip,
      })}
      style={{
        top: `${position.top}px`,
        left: `${position.left}px`,
      }}
    >
      <div className={styles.iconContainer}>
        <div>
          <FontAwesomeIcon icon={faFile} />
        </div>
      </div>
      <div className={styles.infoContainer}>
        <p>
          {itemId === ItemIds.SEPARATE && t("separateAllSums")}
          {itemId === ItemIds.FIRST && t("viewSelectWithFirst")}
          {itemId === ItemIds.ALL && t("viewSelectWithAllAdvert")}
          {itemId === ItemIds.HIDE && t("hideSelected")}
        </p>
      </div>
    </div>
  )
}
export default TooltipInfo